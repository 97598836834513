body {
    margin: 0;
    font-family: 'Manrope-ExtraLight', 'Manrope-Light', 'Manrope-Regular', 'Manrope-Medium', 'Manrope-SemiBold', 'Manrope-Bold', 'Manrope-ExtraBold', 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
}

.hideScrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.noUserSelect {
    user-select: none;
}

@-moz-document url-prefix() {
    .hideScrollbar {
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-color: transparent transparent;
    }
}

#root {
    height: 100%;
}