@font-face {
    font-family: 'Manrope';
    font-weight: 200;
    font-style: normal;
    src: url('../assets/fonts/Manrope/Manrope-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 300;
    font-style: normal;
    src: url('../assets/fonts/Manrope/Manrope-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 400;
    font-style: normal;
    src: url('../assets/fonts/Manrope/Manrope-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 500;
    font-style: normal;
    src: url('../assets/fonts/Manrope/Manrope-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 600;
    font-style: normal;
    src: url('../assets/fonts/Manrope/Manrope-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 700;
    font-style: normal;
    src: url('../assets/fonts/Manrope/Manrope-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope';
    font-weight: 800;
    font-style: normal;
    src: url('../assets/fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope';
    src: url('../assets/fonts/Manrope/Manrope-VariableFont.ttf') format('woff2-variations');
}